<template>
  <div id="layout" class="layout" :class="$route.name === 'aboutCompany' ? 'snapNone' : 'snap'" @scroll="handleScroll">
    <Navbar class="navBar"  :style="setFixed()"></Navbar>
    <AppMain id="appMain">
      <Footer></Footer>
    </AppMain>
    <div v-show="scrollTop > 64 && !isPhone()" @click="scrollToTop(0)" class="layout__top__button anima">
      <i class="iconfont przh-drop-down-black"></i>
    </div>
  </div>
</template>

<script>
import Navbar from "@/layout/components/Navbar";
import AppMain from "@/layout/components/AppMain";
import Footer from "@/layout/components/Footer";

export default {
  name: "Layout",
  components: {
    Navbar,
    AppMain,
    Footer
  },
  provide() {
    return {
      getScrollTop: () => this.scrollTop,
      getInnerHeight: () => this.innerHeight,
      getNavBarHeight: () => this.navBarHeight,
      scrollToTop: this.scrollToTop,
      scrollBool: this.scrollBool
    }
  },
  data() {
    return {
      scrollTop: 0,
      innerHeight: 0,
      navBarHeight: 0,
      currentPage: 0,
      scrollLock: false,
      tempSetTimeOut: null,
      scrollTimeOut: null
    }
  },
  watch: {
    '$route.name'(value) {
      // this.scrollToTop(0);
      // this.scrollTop = 0;
    }
  },
  mounted() {
    this.innerHeight = window.innerHeight;
    this.navBarHeight = Number(getComputedStyle(document.body).getPropertyValue('--nav-bar-height').replace('px', ''))
    window.addEventListener('resize', this.resize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resize);
  },
  methods: {
    handleScroll(e) {
      let scrollTop = e.target.scrollTop;
      let pageHeight = this.innerHeight - this.navBarHeight;
      this.scrollTop = scrollTop;
      document.body.style.setProperty('--nav-bar-top', scrollTop + 'px');
      if (this.scrollTop > 64) {
        document.body.style.setProperty('--nav-bar-background', 'rgba(45, 45, 45)');
      } else {
        if (this.$route.name === 'aboutCompany') {
          document.body.style.setProperty('--nav-bar-background', 'rgba(45, 45, 45)')
        } else {
          document.body.style.setProperty('--nav-bar-background', 'rgba(0, 0, 0, 0)')
        }
      }
    },
    resize(e) {
      this.navBarHeight = Number(getComputedStyle(document.body).getPropertyValue('--nav-bar-height').replace('px', ''))
      this.innerHeight = e.target.innerHeight;
    },
    scrollToTop(pageNumber = 0) {
      console.warn(pageNumber)
      document.getElementById('layout').scrollTo({ top: pageNumber * (this.innerHeight - this.navBarHeight), behavior: 'smooth' });
    },
    isPhone() {
      let info = navigator.userAgent;
      return /mobile/i.test(info);
    },
    setFixed() {
      if (this.isPhone()) {
        return 'position: fixed; top: 0; left: 0;'
      }
      return ''
    },
    scrollBool(index, isLast = false) {
      let topBool = this.scrollTop >= (index) * (this.innerHeight - this.navBarHeight) - (0.5 * this.innerHeight) && this.scrollTop <= (index + 1) * (this.innerHeight - this.navBarHeight) - 0.5 * this.innerHeight + (0.3 * this.innerHeight);
      if (isLast) topBool = this.scrollTop >= (index) * (this.innerHeight - this.navBarHeight) - 20;
      return topBool
    },
  }
}
</script>

<style lang="scss" scoped>
.snap {
  scroll-snap-type: y mandatory;
  -webkit-scroll-snap-type: y mandatory;
}
.layout {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none; /* IE 10+ */
  position: relative;
  overflow-x: hidden;
  //font-family: "PrzhGB";
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  .navBar {
    position: sticky;
    top: 0;
    left: 0;
  }
  //@media screen and (min-width: 600px) {
  //  .navBar {
  //    position: absolute;
  //    top: var(--nav-bar-top);
  //    left: 0;
  //  }
  //}
  .layout__top__button {
    width: 40rem;
    height: 40rem;
    border-radius: 20rem;
    //background: linear-gradient(to top right, #6562cf, #5fa2e5, #c3aa78);
    background: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 15rem #00aaff;
    position: fixed;
    right: 5vw;
    bottom: 40rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    cursor: pointer;
    opacity: 1;
    transition: opacity 1s;
    i {
      color: white;
      font-size: 20rem;
      transform: rotate(180deg);
      font-weight: 600;
    }
    &:hover {
      background: rgba(255, 255, 255);
      i {
        color: black;
      }
    }
  }
  .anima {
    animation-name: likes; // 动画名称
    animation-direction: alternate; // 动画在奇数次（1、3、5...）正向播放，在偶数次（2、4、6...）反向播放。
    animation-timing-function: linear; // 动画执行方式，linear：匀速；ease：先慢再快后慢；ease-in：由慢速开始；ease-out：由慢速结束；ease-in-out：由慢速开始和结束；
    animation-delay: 0s; // 动画延迟时间
    animation-iteration-count: infinite; //  动画播放次数，infinite：一直播放
    animation-duration: 1s; // 动画完成时间
  }
  @keyframes likes {
    0% {
      box-shadow: 0 0 25px #9e9e9e;
    }
    25% {
      box-shadow: 0 0 20px #9e9e9e;
    }
    50% {
      box-shadow: 0 0 15px #9e9e9e;
    }
    75% {
      box-shadow: 0 0 10px #9e9e9e;
    }
    100% {
      box-shadow: 0 0 5px #9e9e9e;
    }
  }
}
</style>