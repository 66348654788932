import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './style/index.scss'
import './assets/przh-icon/iconfont.css'
import i18n from './lang';
import "@/assets/text/text.scss";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueLazyLoad from 'vue-lazyload'
Vue.config.productionTip = false;
Vue.use(VueLazyLoad, {
  preload: 1.3,
  // loading: require('./assets/img/loading.png'), // 正在加载时显示的图片路径
  // error: require('./assets/img/error.png'), // 图片加载失败时显示的图片路径
  attempt: 5, // 图片加载失败后重试次数，默认3
})
Vue.use(ElementUI);
// Vue.use(ElementUI, {
//   i18n: (key, value) => {
//     i18n.t(key, value);
//   }
// });
Vue.prototype.$innerHeight = 0;
Vue.prototype.$scrollTop = 0;
Vue.prototype.$linkTo = (routerName) => {
  router.push({
    name: routerName
  })
  document.getElementById('layout').scrollTo({ top: 0, behavior: 'smooth' })
}
const app = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
})
console.warn(app)
app.$mount('#app')
