<template>
  <div class="przh-button" :class="btnHeightObj[size] ? size : ''" :style="{height: buttonHeight}">
    <div class="przh-button__container">
      <span class="przh-button__text">
        <slot></slot>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrzhButton",
  props: {
    type: {
      type: String,
      default: 'default'
    },
    size: {
      type: String,
      default: 'default'
    }
  },
  data() {
    return {
      btnHeightObj: {
        default: 40,
        medium: 36,
        small: 32,
        mini: 28
      }
    }
  },
  computed: {
    buttonHeight() {
      if (this.btnHeightObj[this.size]) {
        return this.btnHeightObj[this.size] + 'px';
      } else {
        if (isNaN(Number(this.size))) {
          return '0px'
        } else {
          return this.size + 'px';
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
  :root {
    --custom-button--size: 40px;
  }
  .przh-button {
    display: inline-block;
    cursor: pointer;
    .przh-button__container {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 20px;
      border-radius: 5px;
      border: 1px solid #ffffff;
    }
  }
  .przh-button--default {
    height: 40px;
  }
  .przh-button--medium {
    height: 36px;
  }
  .przh-button--small {
    height: 32px;
  }
  .przh-button--mini {
    height: 28px;
  }
</style>