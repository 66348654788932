<template>
  <div v-show="visible" class="pr-drawer__wrapper">
    <div class="pr-drawer__container" @click.self="closeDrawer" @touchmove.prevent>
      <transition name="show-drawer" v-on:before-enter="beforeEnter" v-on:after-leave="afterLeave">
        <div v-if="showDrawer" class="pr-drawer">
          <div class="pr-drawer__header">
            <div v-show="!isPhone" @click="switchLang" @mouseover="switchMouseOver" @mouseout="switchMouseOut">
              <img src="@/assets/svg/CN.svg" v-show="$i18n.locale === 'zh' && !switchHover" />
              <img src="@/assets/svg/CNhover.svg" v-show="$i18n.locale === 'zh' && switchHover" />
              <img src="@/assets/svg/EN.svg" v-show="$i18n.locale === 'en' && !switchHover" />
              <img src="@/assets/svg/ENhover.svg" v-show="$i18n.locale === 'en' && switchHover" />
            </div>
            <div v-show="isPhone" @click="switchLang">
              <img src="@/assets/svg/CN.svg" v-show="$i18n.locale === 'zh'" />
              <!--              <img src="@/assets/svg/CNhover.svg" v-show="$i18n.locale === 'zh' && switchHover" />-->
              <img src="@/assets/svg/EN.svg" v-show="$i18n.locale === 'en'" />
              <!--              <img src="@/assets/svg/ENhover.svg" v-show="$i18n.locale === 'en' && switchHover" />-->
            </div>
            <P @click="closeDrawer">
              <i class="iconfont przh-close-black"></i>
            </P>
          </div>
          <div class="pr-drawer__body">
            <ol>
              <li @click="linkTo('dronesDetail')">
                <span>{{ $t('navigation.drones') }}</span>
              </li>
<!--              <li @click="linkTo('camerasDetail')">-->
<!--                <span>{{ $t('navigation.cameras')}}</span>-->
<!--              </li>-->
              <li @click="linkTo('diyDetail')">
                <span>{{ $t('navigation.diy')}}</span>
              </li>
<!--              <li @click="linkTo('solutionsDetail')">-->
<!--                <span>{{ $t('navigation.solutions')}}</span>-->
<!--              </li>-->
              <li @click="linkTo('aboutCompany')">
                <span>{{ $t('navigation.aboutCompany') }}</span>
              </li>
<!--              <li @click="linkTo('aboutUs')">-->
<!--                <span>{{ $t('navigation.company') }}</span>-->
<!--              </li>-->
            </ol>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrzhDrawer",
  props: {
    visible: {
      type: Boolean
    },
  },
  data() {
    return {
      showDrawer: false,
      switchHover: false,
      isPhone: /mobile/i.test(navigator.userAgent)
    }
  },
  watch: {
    visible(value) {
      this.showDrawer = value;
    }
  },
  methods: {
    closeDrawer() {
      this.showDrawer = false;
    },
    afterLeave() {
      this.$emit('update:visible', false)
    },
    beforeEnter() {
    },
    switchLang() {
      this.$i18n.locale = this.$i18n.locale === 'zh' ? 'en' : 'zh';
      if (this.$i18n.locale == 'zh') {
        document.title = '普瑞智航'
      } else {
        document.title = 'PR'
      }
    },
    switchMouseOver() {
      this.switchHover = true;
    },
    switchMouseOut() {
      this.switchHover = false;
    },
    linkTo(routerName) {
      this.closeDrawer();
      this.$router.push({
        name: routerName
      })
      document.getElementById('layout').scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

}
</script>

<style scoped lang="scss">
  .pr-drawer__wrapper {
    position: fixed;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 999;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: rgba(0, 0, 0, 0.2);
    .pr-drawer__container {
      position: relative;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      .pr-drawer {
        position: absolute;
        right: 0;
        top: 0;
        width: 25%;
        min-width: 331px;
        height: 100%;
        background: white;
        padding: 10px;
        .pr-drawer__header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: 600;
          cursor: pointer;
          padding: 20px 0;
          border-bottom: 2px solid #ccc;
          >p {
            >i {
              font-size: 24px;
              &:hover {
                color: #00aaff;
              }
            }
          }
        }
        .pr-drawer__body {
          display: flex;
          flex-direction: column;
          >ol {
            list-style-type: none;
            >li {
              width: 100%;
              height: 32px;
              padding: 4px 0;
              margin: 8px;
              border-bottom: 1px solid #efefef;
              border-radius: 5px;
              cursor: pointer;
              background: rgba(255, 255, 255);
              display: flex;
              justify-content: flex-start;
              align-items: center;
              span {
                color: #323233;
                padding-left: 20px;
              }
              &:hover {
                background-color: rgba(242, 242, 242);
              }
            }
          }
          //>div {
          //  width: 100%;
          //  height: 32px;
          //  padding: 4px 0;
          //  margin-bottom: 8px;
          //  border-bottom: 1px solid #efefef;
          //}
        }
      }
    }
  }
  .show-drawer-enter-active {
    transition: all 0.3s;
  }
  .show-drawer-enter {
    transform: translateX(100%);
  }
  .show-drawer-leave-active {
    transition: all 0.3s;
  }
  .show-drawer-leave-to {
    transform: translateX(100%);
  }
  .show-drawer-enter-to {
    transform: translateX(0px);
  }


</style>