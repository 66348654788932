import { render, staticRenderFns } from "./PrzhDrawer.vue?vue&type=template&id=4488c5ae&scoped=true&"
import script from "./PrzhDrawer.vue?vue&type=script&lang=js&"
export * from "./PrzhDrawer.vue?vue&type=script&lang=js&"
import style0 from "./PrzhDrawer.vue?vue&type=style&index=0&id=4488c5ae&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4488c5ae",
  null
  
)

export default component.exports