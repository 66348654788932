import Vue from 'vue';
import VueI18n from 'vue-i18n';
import enLocale from './en';
import zhLocale from './zh';
Vue.use(VueI18n);

const messages = {
    en: {
        ...enLocale
    },
    zh: {
        ...zhLocale
    }
};
export function getLanguage() {
    if (navigator.language === 'zh-CN') {
        document.title = '普瑞智航'
        return 'zh';
    }
    document.title = 'PR'
    return 'en';
}
const i18n = new VueI18n({
    locale: getLanguage(),
    messages,
    silentTranslationWarn: true
});

export default i18n;