<template>
  <div class="nav-bar">
    <div class="nav-bar__container">
      <div class="nav-bar__logo">
        <a @click.prevent="$linkTo('home')"></a>
      </div>
      <div class="nav-bar__link " v-if="!isPhone()">
        <p :style="$route.name === 'home' ? 'color: #ffffff;text-shadow: 0 0 4px #000;' : ''" @click="linkTo('home')">{{$t('navigation.home')}}</p>
        <p :style="getDIYStyle()" @click="linkToDiy()">{{ $t('navigation.diy')}}</p>
        <div class="drones-drop">
          <p class="isheader" :style="$route.name === 'dronesDetail' ? 'color: #ffffff;text-shadow: 0 0 4px #000;' : ''">{{ $t('navigation.drones')}}</p>
          <div class="drop-down">
            <p :style="$route.name === 'dronesDetail' ? 'color: #ffffff;' : ''" @click="linkTo('dronesDetail')">P4650</p>
            <p style="padding-bottom: 10rem" :style="$route.name === 'dronesDetail' ? 'color: #ffffff;' : ''" @click="linkTo('dronesDetail')">P4-18</p>
          </div>
        </div>
        <!--        <p :style="$route.name === 'gimbalsDetail' ? 'color: #ffffff;' : ''" @click="linkTo('gimbalsDetail')">{{ $t('navigation.gimbals') }}</p>-->
        <!--        <p :style="$route.name === 'solutionsDetail' ? 'color: #ffffff;' : ''" @click="linkTo('solutionsDetail')">{{ $t('navigation.solutions')}}</p>-->
        <p :style="$route.name === 'aboutCompany' ? 'color: #ffffff;text-shadow: 0 0 4px #000;' : ''" @click="linkTo('aboutCompany')">{{ $t('navigation.aboutCompany')}}</p>
      </div>
      <div v-if="!isPhone()" class="nav-bar__lang-change">
        <div class="nav-bar__lang__box" @click="switchLang" @mouseover="switchMouseOver" @mouseout="switchMouseOut">
          <img src="@/assets/svg/CN-dh.svg" v-show="$i18n.locale === 'zh' && !switchHover" />
          <img src="@/assets/svg/CNhover-dh.svg" v-show="$i18n.locale === 'zh' && switchHover" />
          <img src="@/assets/svg/EN-dh.svg" v-show="$i18n.locale === 'en' && !switchHover" />
          <img src="@/assets/svg/ENhover-dh.svg" v-show="$i18n.locale === 'en' && switchHover" />
        </div>
        <div @click="showDrawer = !showDrawer" class="nav-bar__navigation">
          <span>
            {{ $i18n.locale === 'zh' ? '导航栏' : 'Navigation'}}
          </span>
        </div>
      </div>
      <div v-if="isPhone()" class="nav-bar__lang-change__photo">
        <p @click="showDrawer = !showDrawer">
          <i class="iconfont przh-dh-black"></i>
        </p>
      </div>
    </div>
    <przh-drawer :visible.sync="showDrawer"></przh-drawer>
  </div>
</template>

<script>
import PrzhDrawer from "@/components/PrzhDrawer";
export default {
  name: "Navbar",
  components: {
    PrzhDrawer
  },
  data() {
    return {
      showDrawer: false,
      switchHover: false
    }
  },
  mounted() {
    // window.addEventListener('scroll', this.handleScroll, true);
  },
  methods: {
    getDIYStyle() {
      let styleStr = '';
      styleStr += this.$route.name === 'diyDetail' ? 'color: #ffffff;text-shadow: 0 0 4px #000;' : '';
      return styleStr
    },
    linkTo(routerName) {
      this.$router.push({
        name: routerName
      })
    },
    linkToDiy() {
      window.open('http://diy.pr-uav.com:28081/')
    },
    isPhone() {
      let info = navigator.userAgent;
      return /mobile/i.test(info);
    },
    switchLang() {
      this.$i18n.locale = this.$i18n.locale === 'zh' ? 'en' : 'zh';
      if (this.$i18n.locale == 'zh') {
        document.title = '普瑞智航'
      } else {
        document.title = 'PR'
      }
    },
    switchMouseOver() {
      this.switchHover = true;
    },
    switchMouseOut() {
      this.switchHover = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.nav-bar {
  width: 100%;
  height: var(--nav-bar-height);
  z-index: 998;
  .nav-bar__container {
    transition: background-color 0.5s;
    -webkit-transform: translateZ(0);
    width: 100%;
    height: var(--nav-bar-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--nav-bar-background);
    .nav-bar__logo {
      height: 65.2rem;
      width: 145.2rem;
      padding-left: 20rem;
      //background: red;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      a {
        width: 100%;
        height: 100%;
        display: inline-block;
        //background-image: url("~@/assets/logo.png");
        background-size: auto 80%;
        background-repeat: no-repeat;
      }
    }
    .nav-bar__link {
      display: flex;
      align-items: center;
      >p {
        margin: 0 10rem;
        color: #999999;
        cursor: pointer;
        font-size: 14rem;
        line-height: 16rem;
        min-width: 80rem;
        &:hover {
          color: white;
          text-shadow: 0 0 4px #000;
        }
      }
      .isheader {
        margin: 0 10rem;
        color: #999999;
        cursor: pointer;
        font-size: 14rem;
        line-height: 16rem;
        &:hover {
          color: white;
          +.drop-down {
            opacity: 1;
            transform: translateY(100%) scale(1, 1);
          }
        }
      }
      .drones-drop {
        position: relative;
        min-width: 80rem;
        >p {
          &:hover {
            text-shadow: 0 0 4px #000;
          }
        }
        .drop-down {
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          opacity: 0;
          transition: all 0.3s;
          transform-origin: 50% 0;
          overflow: hidden;
          transform: translateY(100%) scale(1, 0);
          background-color: var(--nav-bar-background);
          border-radius: 0 0 8px 8px;
          &:hover {
            opacity: 1;
            transform: translateY(100%) scale(1, 1);
          }
          p {
            padding-top: 10px;
            margin: 0 10px;
            color: #999999;
            cursor: pointer;
            font-size: 14rem;
            line-height: 16rem;
            text-align: center;
            &:hover {
              color: #ffffff;
              text-shadow: 0 0 4px #000;
            }
          }
        }
      }
    }
    .nav-bar__lang-change {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .nav-bar__lang__box {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      .nav-bar__navigation {
        padding: 7rem 20rem;
        transition: background-color 0.3s;
        background: rgba(35, 166, 240);
        border-radius: 15rem;
        margin: 0 10rem;
        cursor: pointer;
        min-width: 120rem;
        &:hover {
          background: rgba(35, 166, 240, 0.6);
        }
        span {
          font-size: 15rem;
          line-height: 15rem;
          color: #ffffff;
        }
      }
    }
    .nav-bar__lang-change__photo {
      height: 100%;
      width: 100rem;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        font-size: 18rem;
        font-weight: 600;
        color: #ffffff;
        cursor: pointer;
        >i {
          font-size: 30rem;
          color: var(--nav-bar-dh-color);
          &:hover {
            color: #00aaff;
          }
        }
      }
    }
  }
}
</style>