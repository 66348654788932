<template>
  <div class="pr-home">
    <section class="p1-section">
      <div class="section__content">
        <h1 class="b2tAnima przh-transition__default" :class="scrollBool(0) ? 'b2tTo' : 'b2tFrom'">{{$t('home.value1')}}</h1>
        <h1 class="b2tAnima przh-transition__default przh-transition-delay-200ms" style="animation-duration: 1.1s" :class="scrollBool(0) ? 'b2tTo' : 'b2tFrom'">{{$t('home.value2')}}</h1>
      </div>
      <section class="section__banner">

      </section>
    </section>
<!--    <section class="p2-section">-->
<!--      <div class="section-pre">-->
<!--        <i class="iconfont przh-drop-down-black" @click="p2Pre"></i>-->
<!--      </div>-->
<!--      <div class="section-container p2-scroll">-->
<!--        <div class="container__left">-->
<!--          <div class="left-header">-->
<!--            <h1>{{$t('home.value3')}}</h1>-->
<!--          </div>-->
<!--          <div class="left-container">-->
<!--            <div style="border: 1px solid #ffffff">-->
<!--              <h1 class="content&#45;&#45;title przh-transition__default" :class="scrollBool(1) ? 'b2tTo' : 'b2tFrom'">{{$t('home.value4')}}</h1>-->
<!--&lt;!&ndash;              <h2 class="content&#45;&#45;subtitle przh-transition__default przh-transition-delay-100ms" :class="scrollBool(1) ? 'b2tTo' : 'b2tFrom'">探索可能，驱动未来</h2>&ndash;&gt;-->
<!--              <button class="content&#45;&#45;button przh-transition__default przh-transition-delay-300ms" :class="scrollBool(1) ? 'b2tTo' : 'b2tFrom'" @click="linkTo('dronesDetail')">了解更多<i class="iconfont przh-drop-down-white"></i></button>-->
<!--              <img class="przh-transition__default przh-transition-delay-400ms" :class="scrollBool(1) ? 'b2tTo' : 'b2tFrom'" src="@/assets/img/home/banner-01-01.webp" />-->
<!--            </div>-->
<!--            <div style="border: 1px solid #ffffff">-->
<!--              <h1 class="content&#45;&#45;title przh-transition__default" :class="scrollBool(1) ? 'b2tTo' : 'b2tFrom'">{{$t('home.value5')}}</h1>-->
<!--              &lt;!&ndash;              <h2 class="content&#45;&#45;subtitle przh-transition__default przh-transition-delay-100ms" :class="scrollBool(1) ? 'b2tTo' : 'b2tFrom'">探索可能，驱动未来</h2>&ndash;&gt;-->
<!--              <button class="content&#45;&#45;button przh-transition__default przh-transition-delay-300ms" :class="scrollBool(1) ? 'b2tTo' : 'b2tFrom'" @click="linkTo('dronesDetail')">了解更多<i class="iconfont przh-drop-down-white"></i></button>-->
<!--              <img class="przh-transition__default przh-transition-delay-400ms" :class="scrollBool(1) ? 'b2tTo' : 'b2tFrom'" src="@/assets/img/home/banner-01-01.webp" />-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="container__right">-->
<!--          <div class="left-header">-->
<!--            <h1>{{$t('home.value6')}}</h1>-->
<!--          </div>-->
<!--          <div class="left-container">-->
<!--            <div style="border: 1px solid #ffffff">-->
<!--              <img class="przh-transition__default przh-transition-delay-400ms"  :class="scrollBool(1) ? 'b2tTo' : 'b2tFrom'" src="@/assets/img/home/P4720-expand.webp" /></div>-->
<!--            <div style="border: 1px solid #ffffff">-->
<!--              <img class="przh-transition__default przh-transition-delay-400ms"  :class="scrollBool(1) ? 'b2tTo' : 'b2tFrom'" src="@/assets/img/home/P4720-expand.webp" /></div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="section-next">-->
<!--        <i class="iconfont przh-drop-down-black" @click="p2Next"></i>-->
<!--      </div>-->
<!--    </section>-->


<!--    <section class="uav-section scheme-one-section">-->
<!--      <div class="section__content">-->
<!--        <h2 class="content&#45;&#45;subtitle przh-transition__default przh-transition-delay-100ms" :class="scrollBool(1) ? 'b2tTo' : 'b2tFrom'">探索可能，驱动未来</h2>-->
<!--        <button class="content&#45;&#45;button przh-transition__default przh-transition-delay-300ms" :class="scrollBool(1) ? 'b2tTo' : 'b2tFrom'" @click="linkTo('dronesDetail')">了解更多<i class="iconfont przh-drop-down-white"></i></button>-->
<!--        <div class="content&#45;&#45;img przh-transition__default przh-transition-delay-400ms" :class="scrollBool(1) ? 'b2tTo' : 'b2tFrom'">-->
<!--          <img src="@/assets/img/home/banner-01-01.webp" />-->
<!--        </div>-->
<!--      </div>-->
<!--      <section class="section__subContent przh-transition-delay-400ms">-->
<!--        <img class="przh-transition__default przh-transition-delay-400ms"  :class="scrollBool(1) ? 'b2tTo' : 'b2tFrom'" src="@/assets/img/home/P4720-expand.webp" />-->
<!--      </section>-->
<!--    </section>-->
    <section class="diy-section">
      <div class="section__content">
        <h1 class="content--title przh-transition__default" :class="scrollBool(1) ? 'l2rTo' : 'l2rFrom'">{{$t('navigation.diy')}}</h1>
        <h2 class="content--subtitle przh-transition__default przh-transition-delay-100ms" :class="scrollBool(1) ? 'l2rTo' : 'l2rFrom'">{{$t('home.value7')}}</h2>
        <button class="content--button przh-transition__default przh-transition-delay-300ms" :class="scrollBool(1) ? 'l2rTo' : 'l2rFrom'" @click="linkToDiy()">{{$t('home.diyLearnMore')}}<i class="iconfont przh-drop-down-white"></i></button>
      </div>
    </section>
    <section class="p2-section-2">
      <div class="section__header">
        <h1 class="przh-transition__default" :class="scrollBool(2) ? 'b2tTo' : 'b2tFrom'">{{$t('home.value3')}}</h1>
      </div>
      <div class="section__main">
        <div>
          <h1 class="content--title przh-transition__default" :class="scrollBool(2) ? 'b2tTo' : 'b2tFrom'">{{$t('home.value4')}}</h1>
          <button class="content--button przh-transition__default przh-transition-delay-300ms" :class="scrollBool(2) ? 'b2tTo' : 'b2tFrom'" @click="linkTo('dronesDetail')">{{$t('home.LearnMore')}}<i class="iconfont przh-drop-down-white"></i></button>
          <div class="content--img bg">
            <img class="przh-transition__default przh-transition-delay-400ms" :class="scrollBool(2) ? 'b2tTo' : 'b2tFrom'" src="@/assets/img/home/banner-01-01.webp" />
          </div>
        </div>
        <div>
          <h1 class="content--title przh-transition__default" :class="scrollBool(2) ? 'b2tTo' : 'b2tFrom'">{{$t('home.value5')}}</h1>
          <button class="content--button  content--subButton przh-transition__default przh-transition-delay-300ms" :class="scrollBool(2) ? 'b2tTo' : 'b2tFrom'">{{$i18n.locale === 'zh' ? '敬请期待' : 'Coming Soon'}}</button>
          <div class="content--img bg">
            <img style="max-width: 90%; max-height: 90%; margin-top: 40rem" class="przh-transition__default przh-transition-delay-400ms" :class="scrollBool(2) ? 'b2tTo' : 'b2tFrom'" src="@/assets/img/home/p4-18.webp" />
          </div>
        </div>
      </div>
    </section>
<!--    <section class="solutions-section">-->
<!--      <div class="section__content przh-transition__default" :class="scrollBool(3) ? 'rtlTo' : 'r2lFrom'">-->
<!--        <h1 class="content&#45;&#45;title przh-transition__default przh-transition-delay-100ms" :class="scrollBool(3) ? 'rtlTo' : 'r2lFrom'">{{$t('navigation.solutions')}}</h1>-->
<!--        <h2 class="content&#45;&#45;subtitle przh-transition__default przh-transition-delay-200ms" :class="scrollBool(3) ? 'rtlTo' : 'r2lFrom'">{{$t('home.value8')}}</h2>-->
<!--        <button class="content&#45;&#45;button przh-transition__default przh-transition-delay-300ms" :class="scrollBool(3) ? 'rtlTo' : 'r2lFrom'" @click="linkTo('solutionsDetail')">{{$t('home.LearnMore')}}<i class="iconfont przh-drop-down-white"></i></button>-->
<!--      </div>-->
<!--    </section>-->
  </div>
</template>

<script>
import PrzhButton from "@/components/PrzhButton";

export default {
  name: 'HomeView',
  components: {
    PrzhButton
  },
  inject: ['getScrollTop', 'getInnerHeight', 'getNavBarHeight', 'scrollToTop', 'scrollBool'],
  computed: {
    scrollTop() {
      return this.getScrollTop()
    },
    innerHeight() {
      return this.getInnerHeight()
    },
    navBarHeight() {
      return this.getNavBarHeight();
    }
  },
  data() {
    return {
      scrollBool: this.scrollBool
    }
  },
  mounted() {
    document.getElementById('layout').scrollTo({ top: 0 })
  },
  beforeDestroy() {
  },
  methods: {
    linkTo(routerName) {
      this.$router.push({
        name: routerName
      })
      this.scrollToTop(0)
    },
    linkToDiy() {
      window.open('http://diy.pr-uav.com:28081/')
    },
    linkChange(item) {
      this.currentLinkItem = item;
    },
    linkScrollTo(index) {
      this.scrollToTop(index)
    },
    setBorder(index) {
      if (this.scrollTop >= (index) * (this.innerHeight - this.navBarHeight) && this.scrollTop < (index + 1) * (this.innerHeight - this.navBarHeight - 5)) {
        return `border-right: 3rem solid #999999; width: ${window.innerWidth >= 600 ? '21' : '6'}rem;`;
      } else {
        return 'border-right: 1rem solid #999999'
      }
    },
    setStyle(index) {
      if (this.scrollTop >= (index) * (this.innerHeight - this.navBarHeight) && this.scrollTop < (index + 1) * (this.innerHeight - this.navBarHeight - 5)) {
        if (this.show1s) {
          clearTimeout(this.show1s)
        }
        this.show1s = setTimeout(() => {
          if (document.getElementsByClassName('home-navTip__ul')[0]) {
            Array.from(document.getElementsByClassName('home-navTip__ul')[0].children).forEach((item) => {
              item.children[1].style.opacity = 0;
            })
          }
          clearTimeout(this.show1s)
        }, 1000)
        return 'opacity: 1; font-weight: 600;';
      } else {
        return 'opacity: 0;'
      }
    },
    p2Pre() {
      document.getElementsByClassName('p2-scroll')[0].scrollTo({
        left: 0,
        behavior: 'smooth'
      })
    },
    p2Next() {
      document.getElementsByClassName('p2-scroll')[0].scrollTo({
        top: 0,
        left: getComputedStyle(document.getElementsByClassName('p2-scroll')[0]).width.replace('px', ''),
        behavior: 'smooth'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .pr-home {
    >section {
      overflow: hidden;
      height: calc(100vh - var(--nav-bar-height));
      scroll-snap-align: end;
    }
    .p1-section {
      display: flex;
      flex-direction: column;
      height: 100vh;
      padding-top: var(--nav-bar-height);
      background-color: #000000;
      background-image: url("~@/assets/img/home/banner-02.webp");
      background-size: cover;
      box-shadow: 20px 0 20px #000;
      h1 {
        font-size: 45rem;
        color: #000000;
        margin-bottom: 20rem;
        max-width: 80vw;
      }
      span {
        font-size: 18rem;
        color: #ffffff;
        max-width: 80vw;
      }
      .section__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 120rem 0 40rem 0;
        >img {
          max-width: 70vw;
          max-height: 65vh;
        }
      }
      .section__banner {
        flex: 1;
        //background-image: url("~@/assets/img/home/banner-header.webp");
        //background-size: 100% 100%;
      }
    }
    .p2-section-2 {
      display: flex;
      flex-direction: column;
      background-color: #000000;
      .section__header {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        h1 {
          color: #ffffff;
          font-size: 40rem;
        }
      }
      .section__main {
        flex: 9;
        display: flex;
        padding-bottom: 30rem;
        overflow: hidden;
        >div {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: start;
          margin: 0 20rem;
          padding-top: 100rem;
          >img {
            flex: 1;
            max-width: 50vw;
            max-height: 100%;
            padding: 30rem;
          }
          .content--title {
            font-size: 45rem;
            margin-bottom: 45rem;
            color: #ffffff;
          }
          .content--subtitle {
            font-size: 30rem;
            font-weight: 500;
            margin-bottom: 34rem;
            color: #ffffff;
          }
          .content--info {
            font-size: 20rem;
            line-height: 20rem;
            margin-bottom: 37.5rem;
            color: #ffffff;
          }
          .content--button {
            font-size: 20rem;
            background-color: rgba(0, 0, 0, 0);
            line-height: 20rem;
            text-align: center;
            padding: 8rem 15rem;
            border-radius: 18rem;
            border: 0px;
            background-color: rgba(209, 167, 13);
            color: #ffffff;
            cursor: pointer;
            display: flex;
            align-items: center;
            transition: background-color 0.15s;
            i {
              font-size: 16rem;
              display: inline-block;
              transform: rotate(-90deg);
              font-weight: 600;
            }
            &:hover {
              background-color: black;
            }
          }
          .content--subButton {
            background-color: #999999;
            &:hover {
              background-color: #999999;
            }
          }
          .content--img {
            flex: 1;
            overflow: hidden;
            display: flex;
            align-items: start;
            justify-content: center;
            margin-top: 50rem;
            padding: 0 20rem 20rem 20rem;
            img {
              max-width: 100%;
              max-height: 100%;
            }
          }
          .bg {
            background: radial-gradient(ellipse, rgba(50, 50, 50),rgba(30, 30, 30), rgba(10, 10, 10),rgba(8, 8, 8), rgba(0, 0, 0), rgba(0, 0, 0));
          }
        }
      }
    }
    .p2-section {
      display: flex;
      background-color: #000000;
      .section-pre {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          display: inline-block;
          transform: rotate(90deg);
          font-size: 40rem;
          color: #ffffff;
          font-weight: 600;
          cursor: pointer;
        }
      }
      .section-container {
        flex: 12;
        overflow-x: auto;
        overflow-y: hidden;
        display: flex;
        scrollbar-width: none;
        -ms-overflow-style: none; /* IE 10+ */
        &::-webkit-scrollbar {
          display: none; /* Chrome Safari */
        }
        div {
          flex-shrink: 0;
          width: 100%;
          height: 100%;
          display: flex;
          color: #ffffff;
        }
        .container__left {
          display: flex;
          flex-direction: column;
          .left-header {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            h1 {
              font-size: 40rem;
            }
          }
          .left-container {
            flex: 9;
            padding-bottom: 30rem;
            >div {
              flex: 1;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              margin: 0 20rem;
              >img {
                max-width: 50vw;
              }
              .content--title {
                font-size: 45rem;
                margin-bottom: 45rem;
                color: #ffffff;
              }
              .content--subtitle {
                font-size: 30rem;
                font-weight: 500;
                margin-bottom: 34rem;
                color: #ffffff;
              }
              .content--info {
                font-size: 20rem;
                line-height: 20rem;
                margin-bottom: 37.5rem;
                color: #ffffff;
              }
              .content--button {
                font-size: 20rem;
                background-color: rgba(0, 0, 0, 0);
                line-height: 20rem;
                text-align: center;
                padding: 8rem 15rem;
                border-radius: 18rem;
                border: 0px;
                background-color: rgba(209, 167, 13);
                color: #ffffff;
                cursor: pointer;
                display: flex;
                align-items: center;
                transition: background-color 0.15s;
                i {
                  font-size: 16rem;
                  display: inline-block;
                  transform: rotate(-90deg);
                  font-weight: 600;
                }
                &:hover {
                  background-color: black;
                }
              }
              img {
                max-width: 100%;
                margin-top: 30rem;
              }
              .content--img {
                overflow: hidden;
                display: flex;
                align-items: start;
                justify-content: center;
                margin-top: 50rem;
                img {
                  max-width: 100%;
                }
              }
            }
          }
        }
        .container__right {
          display: flex;
          flex-direction: column;
          .left-header {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            h1 {
              font-size: 40rem;
            }
          }
          .left-container {
            flex: 9;
            padding-bottom: 30rem;
            >div {
              flex: 1;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              margin: 0 20rem;
              >img {
                max-width: 50vw;
              }
              .content--title {
                font-size: 45rem;
                margin-bottom: 45rem;
                color: #ffffff;
              }
              .content--subtitle {
                font-size: 30rem;
                font-weight: 500;
                margin-bottom: 34rem;
                color: #ffffff;
              }
              .content--info {
                font-size: 20rem;
                line-height: 20rem;
                margin-bottom: 37.5rem;
                color: #ffffff;
              }
              .content--button {
                font-size: 20rem;
                background-color: rgba(0, 0, 0, 0);
                line-height: 20rem;
                text-align: center;
                padding: 8rem 15rem;
                border-radius: 18rem;
                border: 0px;
                background-color: rgba(209, 167, 13);
                color: #ffffff;
                cursor: pointer;
                display: flex;
                align-items: center;
                transition: background-color 0.15s;
                i {
                  font-size: 16rem;
                  display: inline-block;
                  transform: rotate(-90deg);
                  font-weight: 600;
                }
                &:hover {
                  background-color: black;
                }
              }
              img {
                max-width: 100%;
                margin-top: 30rem;
              }
              .content--img {
                overflow: hidden;
                display: flex;
                align-items: start;
                justify-content: center;
                margin-top: 50rem;
                img {
                  max-width: 100%;
                }
              }
            }
          }
        }
      }
      .section-next {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          display: inline-block;
          transform: rotate(-90deg);
          font-size: 40rem;
          color: #ffffff;
          font-weight: 600;
          cursor: pointer;
        }
      }
    }
    .scheme-one-section{
      height: calc(100vh - var(--nav-bar-height));
      //background-image: url("~@/assets/img/home/banner-01.jpg");
      //background-size: cover;
      //background-position: center bottom;
      background-color: #000000;
      display: flex;
      justify-content: center;
      align-items: start;
      .section__content {
        padding: 0 40rem;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 100rem;
        >img {
          max-width: 50vw;
        }
        .content--title {
          font-size: 45rem;
          margin-bottom: 45rem;
          color: #ffffff;
        }
        .content--subtitle {
          font-size: 30rem;
          font-weight: 500;
          margin-bottom: 34rem;
          color: #ffffff;
        }
        .content--info {
          font-size: 20rem;
          line-height: 20rem;
          margin-bottom: 37.5rem;
          color: #ffffff;
        }
        .content--button {
          font-size: 20rem;
          background-color: rgba(0, 0, 0, 0);
          line-height: 20rem;
          text-align: center;
          padding: 8rem 15rem;
          border-radius: 18rem;
          border: 0px;
          background-color: rgba(209, 167, 13);
          color: #ffffff;
          cursor: pointer;
          display: flex;
          align-items: center;
          transition: background-color 0.15s;
          i {
            font-size: 16rem;
            display: inline-block;
            transform: rotate(-90deg);
            font-weight: 600;
          }
          &:hover {
            background-color: black;
          }
        }
        .content--img {
          flex: 1;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 20rem;
          img {
            max-height: 100%;
            max-width: 100%;
          }
        }
      }
      .section__subContent {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 0 40rem;
        img {
          max-width: 100%;
          max-height: 100%;
          overflow: hidden;
        }
      }
    }
    .solutions-section {
      background-image: url("~@/assets/img/home/banner-03.webp");
      background-color: #000000;
      background-size: 100% 100%;
      background-position: right bottom;
      text-shadow:2px 3px 1px rgba(0, 0, 0, 0.7);
      .content--info {
        font-size: 40rem !important;
        font-weight: 500 !important;
      }
      display: flex;
      justify-content: start;
      align-items: start;
      .section__content {
        display: flex;
        flex-direction: column;
        align-items: start;
        margin-top: 100rem;
        margin-left: 100rem;
        .content--title {
          font-size: 55rem;
          margin-bottom: 30rem;
          color: #ffffff;
        }
        .content--subtitle {
          font-size: 40rem;
          font-weight: 500;
          margin-bottom: 34rem;
          color: #ffffff;
        }
        .content--info {
          font-size: 20rem;
          line-height: 20rem;
          margin-bottom: 37.5rem;
          color: #ffffff;
        }
        .content--button {
          font-size: 20rem;
          background-color: rgba(0, 0, 0, 0);
          line-height: 20rem;
          text-align: center;
          padding: 8rem 15rem;
          border-radius: 18rem;
          border: 0px;
          background-color: rgba(209, 167, 13);
          color: #ffffff;
          cursor: pointer;
          display: flex;
          align-items: center;
          transition: background-color 0.15s;
          i {
            font-size: 16rem;
            display: inline-block;
            transform: rotate(-90deg);
            font-weight: 600;
          }
          &:hover {
            background-color: black;
          }
        }
      }
    }

    .diy-section {
      background-image: url("~@/assets/img/home/banner-diy.webp");
      background-size: cover;
      background-position: left bottom;
      .content--info {
        font-size: 40rem !important;
        font-weight: 500 !important;
      }
      display: flex;
      justify-content: end;
      align-items: start;
      .section__content {
        display: flex;
        flex-direction: column;
        align-items: end;
        margin-top: 100rem;
        margin-right: 100rem;
        color: #ffffff;
        .content--title {
          font-size: 55rem;
          margin-bottom: 30rem;
        }
        .content--subtitle {
          font-size: 40rem;
          font-weight: 500;
          margin-bottom: 34rem;
          max-width: 70vw;
          text-align: end;


        }
        .content--info {
          font-size: 20rem;
          line-height: 20rem;
          margin-bottom: 37.5rem;
        }
        .content--button {
          font-size: 20rem;
          background-color: rgba(0, 0, 0, 0);
          line-height: 20rem;
          text-align: center;
          padding: 8rem 15rem;
          border-radius: 18rem;
          border: 0px;
          background-color: rgba(209, 167, 13);
          color: #ffffff;
          cursor: pointer;
          display: flex;
          align-items: center;
          transition: background-color 0.15s;
          i {
            font-size: 16rem;
            display: inline-block;
            transform: rotate(-90deg);
            font-weight: 600;
          }
          &:hover {
            background-color: black;
          }
        }
      }
    }
  }
</style>
