<template>
  <div class="app-main">
    <router-view></router-view>
    <slot></slot>
  </div>
</template>

<script>
export default {
name: "AppMain"
}
</script>

<style lang="scss" scoped>
.app-main {
  flex: 1;
}
</style>