export default {
    message: {
        hello: 'en123'
    },
    navigation: {
        home: 'Home',
        cameras: 'Cameras',
        diy: 'DIY',
        drones: 'UAV ',
        gimbals: 'Gimbals',
        solutions: 'Industrial Solutions',
        aboutUs: 'About Us',
        company: 'Company',
        aboutCompany: 'Event',
        contactUs: 'Contact Us'
    },
    home: {
        LearnMore: 'Learn More',
        value1: 'Automated aerial platforms and key components with environmental adaption，reliability and scalability',
        value2: '',
        value3: 'Explore Series',
        value4: 'P4650 Quadrotor UAV',
        value5: 'P4-18 Quadrotor UAV',
        value6: '挑战系列多旋翼无人机',
        value7: 'Customized drones tailored to specific scenarios and requirements.',
        value8: 'Solve all problems in a smart and high efficient way',
        diyLearnMore: 'Start DIY'
    },
    drones: {
        P4650: {
            value1: 'P4650',
            value2: 'Tailored for aerial monitoring with long flight time，wind resistance and rainproof',
            value3: 'IP Rating',
            value4: '55min',
            value5: 'Max Flight Time',
            value6: 'Level 8',
            value7: 'Max Wind Resistance',
            value8: '-20℃~55℃',
            value9: 'Working Temperature',
            value10: '-62% Storage Space',
            value11: 'Foldable Design',
            value12: '8km',
            value13: 'Relay Networking',
            value14: 'Specification',
            value15: 'Aircraft Specification',
            value16: 'Aerospace-Grade Aluminum Alloy + Carbon Fiber',
            value17: 'Foldable Design，Reducing Storage Space By 62%',
            value18: 'Dual-drone collaboration, relay operation, easily cope with complex terrain.',
            value19: 'Relay Network With Radius of 4km, UAV Operation With Radius of 8km',
            value20: 'Test',
            value21: 'Wind Resistance Test',
            value22: 'Level 8 wind resistance test was passed',
            value23: 'according to GB/T38930--2020’s test standard',
            value24: 'Waterproof Test',
            value25: 'Waterproof test was passed',
            value26: 'according to GB/T38924.9--2020 Level A test standard',
            value27: 'Dust-proof Test',
            value28: 'Dust-proof test was passed',
            value29: 'according to GB/T38924.10--2020’s test standard',
            value30: 'Low Temperature Test',
            value31: 'Low temperature test was passed',
            value32: 'according to GB/T38924.2--2020 temperate zone test standard',
            value33: ' Battery ',
            value34: 'Remote Control',
            value35: 'Gimbal'
        }
    },
    diy: {
        value1: 'DIY',
        value2: 'Solid and flexible key components, enhancing the convenience and reliability of DIY.',
        value3: 'Core Box',
        value4: 'Aerospace-Grade Aluminum Alloy，High Strength, Light Weight, Large Load Bearing',
        value5: 'Foldable Joint',
        value6: 'High Strength, Lightweight, Reduces Storage Space.',
        value7: 'PDB',
        value8: 'Two-Side Fold',
        value9: 'Four-Side Fold',
        value10: 'Structure Kit',
        value11: 'Structure + Power Kit',
    },
    solutions: {
        value1: 'Case Studies',
        value2: 'Intelligent, high efficiency, flexibility, and ease of use have been widely applied in the power industry.',
        value3: 'Introduction',
        value4: 'Focused on industries, UAV improve efficiency in the power grid industry.',
        value5: 'The geographical environment along the power grid corridor is complex, making it extremely difficult to conduct line inspections under adverse weather conditions. With its comprehensive performance including wind resistance, rain proofing, and long endurance,  P4650 can easily cope with such challenges. ',
        value6: 'The "dual-machine coordination" relay network technology can adapt to all-terrain operating environments and supports MAVLink secondary development.',
        value7: 'Flight Time',
        value8: 'High environmental adaptability',
        value9: 'Industrial Standard',
        value10: 'small and light weight',
        value11: 'Platform Enhancement'
    },
    company: {
        value1: 'Purui Intelligent Drone LTD was established inHengqin District ， Zhuhai , Guangdong province.',
        value2: 'verification model of P4650 successfully completed its maiden flight.',
        value3: 'P4650 was finalized',
        value4: 'The small batches of P4650 was delivered',
        value5: 'The large batches of P4650 was delivered',
        value6: 'verification model of P4750 successfully completed its maiden flight.',
        value8: 'Participate in the world\'s largest drone exhibition (Xponential 2023) held in Denver, USA.',
        value9: 'Participate in GeoConnect Asia held in Singapore',
        value10: 'Research and Development of P4-18'
    }
}