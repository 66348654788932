import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../layout'
import HomeView from "@/views/HomeView";
import detail from "@/views/detail";
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'layout',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'home',
        component: HomeView,
      },
      {
        path: '/detail',
        name: 'detail',
        component: detail
      },
      {
        path: '/camerasDetail',
        name: 'camerasDetail',
        component: () => import('@/views/detail/CamerasDetail')
      },
      {
        path: '/diyDetail',
        name: 'diyDetail',
        component: () => import('@/views/detail/DiyDetail')
      },
      {
        path: '/dronesDetail',
        name: 'dronesDetail',
        component: () => import('@/views/detail/DronesDetail')
      },
      {
        path: '/gimbalsDetail',
        name: 'gimbalsDetail',
        component: () => import('@/views/detail/GimbalsDetail')
      },
      {
        path: '/solutionsDetail',
        name: 'solutionsDetail',
        component: () => import('@/views/detail/SolutionsDetail')
      },
      {
        path: '/aboutUs',
        name: 'aboutUs',
        component: () => import('@/views/detail/aboutUs')
      },
      {
        path: '/aboutCompany',
        name: 'aboutCompany',
        component: () => import('@/views/detail/aboutCompany')
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
