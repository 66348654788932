export default {
    message: {
        hello: 'zh123'
    },
    navigation: {
        home: '首页',
        cameras: '相机',
        diy: 'DIY',
        drones: '多旋翼无人机',
        gimbals: '云台',
        solutions: '行业解决方案',
        aboutUs: '关于我们',
        company: '公司简介',
        aboutCompany: '公司大事记',
        contactUs: '联系我们'
    },
    home: {
        LearnMore: '了解更多',
        value1: '为工业用户提供高可靠性、高环境适应性、高拓展性的',
        value2: '自动飞行平台和关键部件',
        value3: '“探索”系列多旋翼无人机',
        value4: 'P4650四旋翼无人机',
        value5: 'P4-18四旋翼无人机',
        value6: '挑战系列多旋翼无人机',
        value7: '量身定制满足特定场景需求的无人机',
        value8: '灵活简易，智能高效，轻松执行各项任务',
        diyLearnMore: '开始DIY'
    },
    drones: {
        P4650: {
            value1: '“探索”系列—P4650四旋翼无人机',
            value2: '长航时，抗风，防雨，耐低温的空中观测平台',
            value3: '防护等级',
            value4: '55分钟',
            value5: '最长续航时间',
            value6: '8级',
            value7: '抗风等级',
            value8: '-20℃~55℃',
            value9: '工作温度区间',
            value10: '折叠机身',
            value11: '轻松收纳',
            value12: '中继组网',
            value13: '跨障通讯',
            value14: '规格参数',
            value15: '飞行器',
            value16: '航空级铝合金 + 碳纤维机身',
            value17: '可折叠机身设计，轻松收纳',
            value18: '双机协同，中继作业，轻松应对复杂地形挑战',
            value19: '中继半径4km，作业半径8km',
            value20: '千锤百炼，严谨测试',
            value21: '抗风性测试',
            value22: '依据GB/T38930--2020',
            value23: '通过同等测试标准下8级抗风测试',
            value24: '防水性测试',
            value25: '依据GB/T38924.9--2020',
            value26: '通过同等测试条件下A类设备防水测试',
            value27: '防尘性测试',
            value28: '依据GB/T38924.10--2020',
            value29: '通过同等测试条件下沙尘测试',
            value30: '低温测试',
            value31: '依据GB/T38924.2--2020',
            value32: '通过同等测试条件下温带地域低温测试',
            value33: '标配电池',
            value34: '遥控器',
            value35: '云台安装方式'
        }
    },
// {{$t('solutions.value1')}}
    diy: {
        value1: 'DIY',
        value2: '关键结构部件+动力总成，提升DIY的便利性、可靠性',
        value3: '核心舱',
        value4: '航空级铝合金打造的高强度，轻量化，重承载核心结构件',
        value5: '折叠关节',
        value6: '高强度，轻量化，大幅减小收纳面积的核心折叠件',
        value7: '分电板',
        value8: '长方形折叠',
        value9: '正方形折叠',
        value10: '结构套件',
        value11: '结构 + 动力套件',
    },
    solutions: {
        value1: '行业应用',
        value2: '以智能、高效、灵活、简单易用的优势，在电力行业得到了广泛应用',
        value3: '方案介绍',
        value4: '深耕行业领域，无人机赋能智慧电网',
        value5: '电网沿线走廊地理环境复杂，在恶劣气象情况下进行线路巡检极其困难。普瑞P4650凭借自身抗风、防雨、超长航时等综合性能从容应对。',
        value6: '“双机协同”中继技术可以适应全地形作业环境，同时支持MavLink二次开发。',
        value7: '飞行时间',
        value8: '高环境适应性',
        value9: '工业级标准',
        value10: '小巧轻便',
        value11: '开放式平台'
    },
    company: {
        value1: '公司在广东省珠海市横琴成立',
        value2: 'P4650验证机首飞成功',
        value3: 'P4650定型',
        value4: 'P4650小批量交付',
        value5: 'P4650大批量交付',
        value6: 'P4750验证机首飞成功',
        value7: '公司在广东省珠海市横琴成立',
        value8: '参加在美国丹佛举办的全球最大无人机（Xponential 2023）展会',
        value9: '参加在新加坡举办的Geo Connect',
        value10: '2024年2月研发P4-18'
    }
}