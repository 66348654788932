<template>
  <div class="footer">
    <div class="footer__container">
      <div class="footer__body" v-if="!IsPhone()">
        <div class="footer__body__logo">
          <a @click.prevent="$linkTo('home')"></a>
        </div>
<!--        <div class="footer__body__about">-->
<!--          <h4>{{$t('navigation.aboutUs')}}</h4>-->
<!--&lt;!&ndash;          <p @click="linkTo('aboutUs')">{{$t('navigation.company')}}</p>&ndash;&gt;-->
<!--        </div>-->
<!--        <div class="footer__body__navigation">-->
<!--          <h4>{{$i18n.locale === 'zh' ? '导航' : 'Navigation'}}</h4>-->
<!--          <p @click="linkTo('dronesDetail')">{{ $t('navigation.drones')}}</p>-->
<!--&lt;!&ndash;          <p @click="linkTo('gimbalsDetail')">{{ $t('navigation.gimbals') }}</p>&ndash;&gt;-->
<!--          <p @click="linkTo('diyDetail')">{{ $t('navigation.diy')}}</p>-->
<!--          <p @click="linkTo('solutionsDetail')">{{ $t('navigation.solutions')}}</p>-->
<!--        </div>-->
        <div class="footer__body__touch">
          <h4>{{$t('navigation.contactUs')}}</h4>
          <p><i class="iconfont przh-phone-black"></i>+86-0756-8878590</p>
          <p><i class="iconfont przh-coordinate-black"></i>{{$i18n.locale === 'zh' ? '珠海市市辖区高新区唐家湾镇软件园路1号生产加工中心5#二层4单元' : '2/F，Building B5 East, Hi-tech Zone,Zhuhai,Guangdong,China'}}</p>
          <p><i class="iconfont przh-email-black"></i>purui@puruizh.com</p>
        </div>
      </div>
      <div class="footer__body__photo" v-if="IsPhone()">
        <div class="footer__body__about">
          <div class="footer__nav__header" @click="changeFoldStatus('aboutIsOpen')">
            <h5>{{$t('navigation.aboutUs')}}</h5>
            <i class="iconfont przh-drop-down-white" :style="aboutIsOpen ? 'transform: rotate(180deg)' : 'transform: rotate(0deg)'"></i>
          </div>
          <div :class="aboutIsOpen ? 'isOpen' : 'isFold'" class="footer__nav__body">
            <p>{{$t('navigation.company')}}</p>
          </div>
        </div>
        <div class="footer__body__navigation">
          <div class="footer__nav__header" @click="changeFoldStatus('navIsOpen')">
            <h5>{{$t('navigation.contactUs')}}</h5>
            <i class="iconfont przh-drop-down-white" :style="navIsOpen ? 'transform: rotate(180deg)' : 'transform: rotate(0deg)'"></i>
          </div>
          <div :class="navIsOpen ? 'isOpen' : 'isFold'" class="footer__nav__body">
            <p @click="linkTo('dronesDetail')">{{ $t('navigation.drones')}}</p>
            <p @click="linkTo('gimbalsDetail')">{{ $t('navigation.gimbals') }}</p>
            <P @click="linkTo('camerasDetail')">{{ $t('navigation.cameras')}}</P>
            <p @click="linkTo('diyDetail')">{{ $t('navigation.diy')}}</p>
            <p @click="linkTo('solutionsDetail')">{{ $t('navigation.solutions')}}</p>
          </div>
        </div>
      </div>
      <div class="footer__copyright">
        <div>
<!--        <span class="fl" style="margin-right: 15px">-->
<!--          <span>Tel: +86-XXX-XXXXXXX</span>-->
<!--          <span>+86-XXX-XXXXXXX</span>-->
<!--          <span>E-mail: XXXXXX@XXXX.com</span>-->
<!--        </span>-->
          <a target="_blank" href="https://beian.miit.gov.cn/" v-show="$i18n.locale == 'zh'" @click="openBeian"> <img src="@/assets/img/bei.png" style="float:left;"/> <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">粤公网安备 44049102496934号 粤ICP备2023034507号</p ></a>
<!--          <span>Copyright © 2023 粤ICP备2023034507号</span>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      isPhone: /mobile/i.test(navigator.userAgent),
      aboutIsOpen: false,
      navIsOpen: false,
      relatedIsOpen: false
    }
  },
  mounted() {

  },
  methods: {
    IsPhone() {
      // let info = navigator.userAgent;
      // console.warn('isPhone', /mobile/i.test(info))
      // return /mobile/i.test(info);
      return window.innerWidth < 600;
    },
    linkTo(routerName) {
      this.$router.push({
        name: routerName
      })
      document.getElementById('layout').scrollTo({ top: 0, behavior: 'smooth' })
    },
    changeFoldStatus(boolName) {
      if (this[boolName]) {
        this[boolName] = false;
        return;
      }
      this.relatedIsOpen = false;
      this.aboutIsOpen = false;
      this.navIsOpen = false;
      this[boolName] = true;
    },
    openBeian() {
      window.open("https://beian.miit.gov.cn/")
    }
  }
}
</script>

<style scoped lang="scss">
  .footer {
    width: 100%;
    background: var(--nav-bar-background);
    scroll-snap-align: end;
    .footer__body__logo {
      padding-left: 20rem;
      width: 145rem;
      height: 65rem;
      //background: red;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      a {
        width: 100%;
        height: 100%;
        display: inline-block;
        //background-image: url("~@/assets/logo.png");
        background-size: 100%;
        background-repeat: no-repeat;
      }
    }
  }
  @media screen and (max-width:599px) {
    h5 {
      line-height: 30px;
      color: rgba(245, 246, 246, 1);
      display: flex;
    }
    .footer__body__photo {
      display: flex;
      flex-direction: column;
      >div{
        .footer__nav__body {
          overflow: hidden;
          transition: all 0.5s ease-out;
          -webkit-transition: all 0.8s ease-out;
          padding: 0 20px;
        }
        .footer__nav__header {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 18px 20px 0;
          i {
            color: white;
            transition: all 0.8s;
            font-size: 12px;
          }
        }
        .isOpen {
          max-height: 200px;
        }
        .isFold {
          max-height: 0;
        }
        p {
          color: #999999;
          font-size: 14px;
          line-height: 30px;
          cursor: pointer;
          display: flex;
        }
      }
    }
    .footer__copyright {
      display: flex;
      padding: 15px 0;
      background: rgba(3, 13, 32, 0.08);
      div {
        width: 100%;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        .fl {
          display: flex;
          flex-direction: column;
        }
        span {
          line-height: 30px;
          font-size: 12px;
          font-family: ArialMT;
          color: #999999;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }
  @media screen and (min-width: 600px) {
    h4 {
      color: rgba(245, 246, 246, 1);
    }
    .footer {
      //font-family: "PrzhGB";
      .footer__body {
        display: flex;
        justify-content: space-around;
        padding: 40px 0;
        >div {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          p {
            color: #999999;
            font-size: 14px;
            line-height: 30px;
            cursor: pointer;
            &:hover {
              color: rgba(245, 246, 246, 1);
            }
          }
        }
        .footer__body__touch {
          i {
            color: white;
            margin-right: 5px;
          }
        }
      }
      .footer__copyright {
        display: flex;
        padding: 15px 0;
        border-top: 1px solid #999999;
        background: rgba(3, 13, 32, 0.08);
        div {
          width: 100%;
          display: flex;
          justify-content: space-around;
          span {
            line-height: 30px;
            font-size: 12px;
            font-family: ArialMT;
            color: #999999;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
      }
    }
  }
</style>